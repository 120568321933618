// Fonts
//@import url('https://fonts.googleapis.com/css2?family=Fira+Sans');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

// Adobe Fonts (Ethan's Account)
@import url("https://use.typekit.net/jma1rjn.css");

// Variables
@import 'variables';

// Helpers
@import 'helpers';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Font Awesome Free
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';

@import "~toastr/toastr";
@import "../css/HoldOn.min.css";

*,*:focus,*:hover{
    outline:none;
}


html {
    scroll-behavior: smooth;
}

// Media Queries
@media (max-width: 767px) {
    .banner-text-mobile {
        bottom: 0 !important;
        left: 0px !important;
        font-size: 25px !important;
    }
    .slick-text-mobile {
        font-size: 34px !important;
    }
    .slick-banner-mobile{
        min-height: 40vh!important;
    }
    .banner-size-mobile {
        background-attachment: scroll !important;
        min-height: 30vh !important;
    }
}

@media (max-width: 992px) {
    .logo-mobile {
        width: 250px!important;
    }
    .padding-top-mobile {
        padding-top: 7rem!important;
    }
}

@media (max-width: 1024px) {
    .section-spacer{
        padding-bottom: 1rem!important;
        padding-top: 1rem!important;
    }
    .burger-button-text{
        font-size: 27px!important;
    }
}


// Backgrounds
.bg-gradient {
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0) 100%);z-index:1
}

.bg-black {
    background-color: #000;
}

.bg-gold{
    background-color: #E8D47E;
}

.bg-silver {
    background-color: #E8E8E8;
}

// Banners
.ar {
    width: 100%;
    padding-top: 100%;
    position: relative;
}

.ar-16x9 {
    padding-top: 56.25%;
}

.ar-home-banner {
    /*padding-top: 51.04%;*/
    padding-top: 45%;
}

.ar-news-thumb {
    padding-top: 60%;
}

.ar-inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.ar-bg-image {
    background-position: center;
    background-size: cover;
}


// Overwrites

.btn {
    //border-radius: 0;
    font-weight: bold;
    text-transform: uppercase;
}

.cursor-pointer {
    cursor:pointer;
}

// This is for the font awesome icons
i {
    font-size: 45px;
}

// Custom Styles

.container-slim {
    max-width: 630px;
}

h1, h2, h3, h4, h5, h6{
    color: $black;
}

footer {
    border-top: 1px solid #dddddd;
}

.font-size-small {
    font-size: 15px;
}

.text-shadow {
    text-shadow: 0 0 8px rgba(0,0,0,0.5);
}

.footer-logo {
    max-width: 200px;
}

.footer-social {
    max-width: 32px;
    display: inline-block;
}

.footer-bar {
    //border-top: 1px solid #DBA052;
    background: #333333;
}

.parallax {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 70vh;
}

.background-image{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 70vh;
}

.nav-link > img {
    height:24px;
}

.navbar-brand > img {
    height:48px;
}

.nav-link {
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    transition: 250ms;
}

.nav-link.active{
    //border-bottom: 3px solid #00BEFF;
    transition: 250ms;
}
//
//.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active {
//    border-bottom: 3px solid #00BEFF;
//    transition: 250ms;
//}


.markdown-white > h1,
.markdown-white > h2,
.markdown-white > h3,
.markdown-white > h4,
.markdown-white > h5,
.markdown-white > h6 {
    color: #FFFFFF;
}

.card-border-radius{
    border-radius: 20px;
}

.border-gold {
    border: 1px solid silver;
}

.bar1, .bar2, .bar3 {
    width: 30px;
    height: 3px;
    background-color: #ffffff;
    margin: 6px 0;
    transition: 0.4s;
}

.close {
    position: absolute;
    right: 8px;
    top: 7px;
    width: 32px;
    height: 32px;
    opacity: 1;
    &:hover {
        color: #F04E11;
    }
    &:before {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 33px;
        width: 4px;
        background-color: #333;
        transform: rotate(45deg);
    }
    &:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 33px;
        width: 4px;
        background-color: #333;
        transform: rotate(-45deg);
    }
}

//.change .bar1 {
//    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
//    transform: rotate(-45deg) translate(-9px, 6px);
//}
//
//.change .bar2 {opacity: 0;}
//
//.change .bar3 {
//    -webkit-transform: rotate(45deg) translate(-8px, -8px);
//    transform: rotate(45deg) translate(-8px, -8px);
//}

#burger {
    /*margin-top:20px;*/
    /*margin-bottom: 20px;*/
    margin-left: 2px;
    margin-right: 2px;
}

.burger-button {
    padding: .45rem .5rem;
    background: rgba(108, 107, 134, 1);
    z-index: 99999;
    cursor: pointer;
    border-radius:50%;
    /*width:90px;*/
    /*height:90px;*/
    margin: 7px 11px;
    //right:0;
    transition: .25s;

}

.burger-button:hover {
    padding: .45rem .5rem;
    background: rgba(240, 78, 17, 1);
    z-index: 99999;
    transition: .25s;
    border-radius:64px;
}

.burger-button-text{
    font-size: 44px;
}

.burger-button-text:hover{
    color: black!important;
}

.section-spacer {
    padding-top: 2rem;
    padding-bottom: 5rem;
}

.briefcase-wrapper {
    border-radius: .5rem;
    overflow: hidden;
    cursor: pointer;
    transition: .25s;
    &.active .briefcase-title, &:hover .briefcase-title, &.active .briefcase-body, &:hover .briefcase-body {
        background: #F04E11;
    }
    &.active .briefcase-handle-inner, &:hover .briefcase-handle-inner {
        border-color: #F04E11;
        color: #F04E11;
    }
}

.briefcase-handle {

}

.briefcase-handle-inner {
    width: 38px;
    height: 16px;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    border-color: #000000;
    color: #000000;
    border-top: 5px solid;
    border-left: 5px solid;
    border-right: 5px solid;
    border-bottom: 0;
    margin-left:auto;
    margin-right:auto;
    transition: .25s;
}

.briefcase-title {
    min-height: 34px;
    background: #000000;
    color: #FFFFFF;
    padding: .25rem;
    font-weight: bold;
    font-size: 1.5rem;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    transition: .25s;
}

.briefcase-body {
    min-height: 177px;
    background: #24242d;
    color: #FFFFFF;
    padding: 0.25rem;
    font-weight: bold;
    font-size: 1.25rem;
    transition: .25s;
}

a:hover {
    text-decoration: none;
}

.briefcase-image {
    width: 100%;
    padding-top: 53.68%;
    position: relative;
    background-size:cover;
    &:hover .briefcase-image-inner {
        opacity: 1;
    }
}

.briefcase-image-inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    background-color: rgb(240, 78, 17,.5);
    transition: .25s;
}

.briefcase-image-inner-text {
    color: white;
    font-size: 4rem;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.orb-wrapper {
    width:46px;
    height:46px;
    position: relative;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    //border:1px solid blue;
    //margin-top:26px;
}

.orb {
    width:46px;
    height:46px;
    position: absolute;
    right:0;
    transition: .25s;
    &:hover .orb-text {
        width: auto;
        padding-right: 50px;
    }
}

.orb-icon {
    width:46px;
    height:46px;
    border-radius: 23px;
    position: absolute;
    right:0;
    background:#6C6B86;
    text-align: center;
    transition: .25s;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background: #f04e11;
    }
    i {
        font-size:1.5rem;
        color: #FFFFFF;
        line-height: 46px;
    }
    img {
        width: 70%;
    }
}

.orb-text {
    width:46px;
    height:46px;
    line-height:46px;
    border-radius: 23px;
    position: absolute;
    right:0;
    background:#6C6B86;
    overflow: hidden;
    transition: .25s;
    color: #FFFFFF;
    font-size:1rem;
    font-weight: bold;
    padding-left:1rem;
    padding-right: 0;
    text-transform: lowercase;

}

#video-orb-wrapper {
    cursor: pointer;
    font-weight: bold;

    .orb-text {
        white-space: nowrap;
        text-transform: capitalize;
        background-color: #ffffff;
        color: #ff6501;
    }

    &:hover .orb-text {
        width: auto;
        padding-right: 50px;
        background-color: #ff6501;
        color: #ffffff !important;
    }

    &:hover .orb-icon {
        background-color: #ff6501;
    }

    &.available .orb-text {
        width: auto;
        padding-right: 50px;
        color: #13b224;
    }

    &.available:hover .orb-text {
        background-color: #13b224;
    }

    &.available .orb-icon {
        background-color: #13b224;
    }
}

.close-icon {
    width: 46px;
    height: 46px;
    border-radius: 23px;
    position: absolute;
    right: 0;
    background: #fff;
    text-align: center;
    transition: .25s;
    cursor: pointer;
    //&:hover {
    //    background: #666666;
    //}
    i {
        font-size: 3rem;
        color: #000;
        line-height: 46px;
        transition: .25s;
        &:hover {
            color: #F04E10;
        }
    }
}

.contactMain {
    font-weight: 700;
    font-size: 1.25rem;
    color:#000000;
}

.contactColon {
    font-weight: 700;
    font-size: 1.25rem;
    color:#F04E10;
}

.contactSubtext {
    font-weight: 400;
    font-size: 1.25rem;
    color:#000000;
}

.orange {
    color: #F04E10;
}

.single-letter {
    color: #F04E10;
    font-size: 43px;
}

ul {
    list-style: none;
    padding-left: 0;
}

li.orange-bullet::before{
    content: "• ";
    color: #F04E10;
}

.form-control {
    background-color: #f1f1f4;
    border: none;
}

.background-grey{
    background: #24242d!important;
}

.text-grey{
    color: #24242d;
}

#marketing-background {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/images/marketing-banner.jpg');
}
#branding-background {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/images/branding-banner.jpg');
}
#publishing-background {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/images/publishing-banner.jpg');
}

.fa-times-thin:before {
    content: '\00d7';
}

.js {
    .cd-top--fade-out {
        opacity: .5;
    }
    .cd-top--is-visible {
        visibility: visible;
        opacity: 1;
    }
    .cd-top {
        visibility: hidden;
        opacity: 0;
        transition: opacity .3s, visibility .3s, background-color .3s;
    }
}
#toTopBtn {
    position: fixed;
    bottom: 46px;
    right: 39px;
    z-index: 98;
    padding: 21px;
    background-color: #666666;
    border-radius: 30px;
}
.cd-top {
    position: fixed;
    bottom: 20px;
    bottom: var(--cd-back-to-top-margin);
    right: 20px;
    right: var(--cd-back-to-top-margin);
    display: inline-block;
    height: 40px;
    height: var(--cd-back-to-top-size);
    width: 40px;
    width: var(--cd-back-to-top-size);
    box-shadow: 0 0 10px rgba(0, 0, 0, .05) !important;
    background: url('/images/top-arrow.png') no-repeat center 50%;
    background-color: hsla(5, 76%, 62%, .8);
    background-size: contain;
    background-color: hsla(var(--cd-color-3-h), var(--cd-color-3-s), var(--cd-color-3-l), 0.8);
}
.height {
    height: 3000px;
}

.send-button {
    background-color: #6C6B86;
    transition: .25s;
    &:hover {
        background-color: #F04E10;
    }
}

.text-client-grey{
    color: #6C6B86;
}

.contact-placeholder::placeholder {
    color: #6C6B86;
}

.times {
    background-image: url('/images/times.png');
    background-repeat: no-repeat;
    background-size: 26px;
    background-position: 10px 9px
}

.times:hover {
    background-image: url('/images/times-orange.png')!important;
}

#meeting-modal {
    position: fixed;
    top:  0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.75);
    z-index: 9;
}

#meeting-modal-inner {
    background-image: url(/images/TMC4.jpg);
    position: fixed;
    width: 75%;
    height: 75%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 1em;
    * {
        color: white;
    }
    h1 {
        margin-bottom: 1.5em;
        font-size: 2em;
    }
    p {
        font-size: 0.8em;
        margin-top: 1em;
    }
}

#modal-close {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    color: black;
    background-color: white;
    border: 0.15em solid black;
    border-radius: 1em;
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
}

#meeting-modal-available, #meeting-modal-unavailable {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 75%;
    position: absolute;
    top: 12.5%;
    font-size: 2em;
}

#meeting-modal.show-form #meeting-modal-available, #meeting-modal.show-form #meeting-modal-unavailable {
    display: none !important;
}

#meeting-modal .long-pill {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 0.25rem;
    padding-right: 0.75rem;
    color: #6C6B86;
    border-radius: 1em;
    div {
        width: 2em;
        height: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.5em;
        border-radius: 1em;
        img {
            width: 80%;
        }
    }
}

#meeting-modal-form {
    display: none;
    background-color: rgba(255, 101, 1, 0.75);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2.25em 2em;
    border-radius: 1em;
    width: 100%;
    max-width: 50em;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    p {
        margin-top: 0;
        font-size: 1.5em;
        padding-right: 0.5rem;
    }
    form {
        display: flex;
        flex-direction: column;
    }
    input {
        margin-bottom: 0.75rem;
    }
    textarea {
        flex-grow: 1;
    }
    input, textarea {
        width: 100%;
        border: none;
        padding: 0.25rem;
        color: black;
    }
    button {
        color: white;
        background-color: #F04E10;
        font-weight: bold;
        border: none;
        transition: 0.25s;
        &:hover {
            background-color: #13b224;
        }
    }
    #form-back {
        user-select: none;
        cursor: pointer;
    }
}

#meeting-modal.show-form #meeting-modal-form {
    display: grid;
}

#dolly_connect_lightbox {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    text-align: center;
    position: fixed;
    bottom: 2em;
    z-index: 999;
    left: 2em;
    background-color: white;
    padding: 2em;
    box-shadow: 0 10px 20px rgba(0,0,0,.15);

    .close-icon{
        float: right;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        background-color: #6C6B86;
    }

    .close {
        position: absolute;
        opacity: 0.3;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }

        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }

        &:before, &:after {
            position: absolute;
            left: 15px;
            content: ' ';
            height: 33px;
            width: 2px;
            background-color: #333;
        }
    }

    #plus {
        display: none;
        margin-left: 1rem;
        font-size: 2rem;
    }

    .profile-bubble {
        border-radius:50%;
    }

    .briefcase-body-text img {
        display: none;
        width: 2.5rem;
        margin-right: 1rem;
    }


    &.small {
        left: 0;
        bottom: 0;
        padding: 0em 2.5em;
        cursor: pointer;
        background-color: #f04e11;
        color: white;

        #dolly_connect_content {
            display: none;
        }

        .briefcase-body-text {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.5em;

            img {
                display: block;
            }
        }

        .close {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;

            &:before, &:after {
                display: none;
            }
        }

        #plus {
            display: block;
        }
    }

    @keyframes blinkingButton{
        0%{     background-color: lime;    }
        50%{    background-color: lightgreen; }
        100%{    background-color: lime; }
    }

    button.join-live {
        border: none;
        background: lime;
        padding: 5px 15px;
        margin: 10px;
        animation:blinkingButton 1.2s infinite;
        font-size: 24px;
        font-family: "Lato", sans-serif;
        font-weight: 700;
    }

    .concentric-circles {
        cursor: pointer;
        margin-top: 10px;
        border-radius: 50%;
        transition: all 0.2s ease-in-out;
        animation: ripple 2.0s 0.2s infinite;
        -webkit-animation: ripple 2.0s 0.2s  infinite;
        transform: scale(0.8);

        &:hover {
            transform: scale(1);
        }
    }

    @-webkit-keyframes ripple {
        20% {
            box-shadow: 0px 0px 0px 6px #fff,
            0px 0px 0px 8px #f04e11;
        }

        50% {
            box-shadow: 0px 0px 0px 6px #fff,
            0px 0px 0px 8px #f04e11,
            0px 0px 0px 14px #fff,
            0px 0px 2px 16px #f04e11;
        }

        100% {
            box-shadow: 0px 0px 0px 6px #fff,
            0px 0px 0px 8px #f04e11,
            0px 0px 0px 14px #fff,
            0px 0px 2px 16px #f04e11,
            0px 0px 0px 22px #fff,
            0px 0px 5px 24px #f04e11;
        }
    }
}

@media (screen and max-width: 767px) {
    #dolly_connect_lightbox {
        width: calc(100% - 4em);
    }
    #dolly_connect_lightbox.small {
        width: 100%;
    }
}

.font-wf-ribbon {
    font-weight: 500;
    font-size:36px;
    letter-spacing: 2px;
    color:#FFFFFF;
    line-height: 1.5;
}

.font-wf-standard {
    font-weight: 400;
    font-size:29px;
    letter-spacing: 0;
    color:#707070;
    line-height: 1.5;
}

.font-wf-large {
    font-weight: 400;
    font-size:36px;
    letter-spacing: 0;
    color:#707070;
    line-height: 1.5;
}

.social-video-wrapper {
    position: relative;
    width: 100%;
    //padding-top: 177.77%; /* 9:16 Aspect Ratio */
    padding-top: 170%;
    background-size: cover;
    background-position: center;
    border: 2px solid #ffffff;
    transition: 500ms;
    overflow: hidden;
    &:hover {
        border: 2px solid #AA63A5;
    }
    &:hover .social-video-icon {
        transform:scale(1.25);
    }
}

.social-video-inner {
    position:  absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    font-size: 20px;
    color: white;
}

.social-video-icon {
    transition: 500ms;
    font-size: 4rem;
}

.trbl {
    top:0;
    right:0;
    bottom:0;
    left:0;
}

.hover-video > div {
    opacity: 1;
    transition: 250ms;
}
.hover-video:hover > div {
    opacity: 0;
    transition: 250ms;
}
